import Button from "react-bootstrap/Button";

import {FiLogOut} from "react-icons/fi";
import {connect} from "react-redux";

import {Link, useLocation} from "react-router-dom";

import {createStructuredSelector} from "reselect";
import {setCurrentDistro} from "../../redux/distro/distro.actions";

import {setCurrentUser} from "../../redux/user/user.actions";
import {selectCurrentUser} from "../../redux/user/user.selectors";
import {advisorSidebarData, ownerSidebarData} from "./sidebarData";

import "./sidebar.scss";

const Sidebar = ({
                     currentUser,
                     setCurrentUser,
                     setCurrentDistro,
                     showSidebar,
                 }) => {
    const location = useLocation();

    const sidebarData =
        currentUser.role === "owner" ? ownerSidebarData : advisorSidebarData;

    return (
        <div className={`sidebar-container ${showSidebar ? "show-sidebar" : null}`}>
            {sidebarData.map((item, idx) => {
                return (
                    <div key={idx} className={`sidebar-section-container ${
                        item.section === "Account" ? "logout-container" : ""
                    }`}>
                        <h2 className="sidebar-section-title">{item.section}</h2>
                        {item.links.map((link) => {
                            return !link.externalLink ? (
                                <Link
                                    key={link.title}
                                    className={`sidebar__link ${
                                        location.pathname === link.path
                                            ? "sidebar__link-active"
                                            : null
                                    }`}
                                    to={link.path}
                                >
                                    {link.title}
                                </Link>
                            ) : (
                                <Link
                                    key={link.title}
                                    className="sidebar__link"
                                    to={{
                                        pathname: `${link.path}`,
                                    }}
                                    target="_blank"
                                >
                                    {link.title}
                                </Link>
                            );
                        })}
                        {
                            item.section === "Account" && <Button
                                className="px-4 m-3 logout-btn d-flex align-items-center justify-content-center"
                                variant={"outline-primary"}
                                type="submit"
                                onClick={() => {
                                    setCurrentDistro(null);
                                    setCurrentUser();
                                }}
                                style={{gap: "8px"}}
                            >
                                <FiLogOut/>
                                Logout
                            </Button>

                        }
                    </div>
                );
            })}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: () => dispatch(setCurrentUser()),
    setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
